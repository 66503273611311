<template>
   <b-row class="mt-2">
	  <b-col
		  class="d-flex justify-content-start flex-column"
		  cols="12"
		  md="3">
		 <div class="mb-1">
			<h6 class="section-label mb-0">E-MAIL</h6>
			<h6 class="mb-0">{{ contact.email || '-' }}</h6>
		 </div>
		 <div class="mb-1">
			<h6 class="section-label mb-0">{{ $t('contacts.uphone') }}</h6>
			<h6 class="mb-0">{{ contact.telephone || '-' }}</h6>
		 </div>
		 <div class="mb-1">
			<h6 class="section-label mb-0">{{ $t('contacts.umobile') }}</h6>
			<h6 class="mb-0">{{ contact.mobile || '-' }}</h6>
		 </div>
		 <div class="mb-1">
			<h6 class="section-label mb-0">{{ $t('contacts.ucompany') }}</h6>
			<h6 v-if="contact.company"
				class="mb-0">{{ contact.company.name || '-' }}</h6>
			<h6 v-else>-</h6>
		 </div>
		 <div class="mb-1">
			<h6 class="section-label mb-0">{{ $t('contacts.company-vat') }}</h6>
			<h6 v-if="contact.company"
				class="mb-0">{{ contact.company.vat || '-' }}</h6>
			<h6 v-else>-</h6>
		 </div>
	  </b-col>

	  <b-col cols="12"
			 md="9">
		 <div class="d-flex align-items-center mb-3">
			<div class="d-flex align-items-center mr-2">
			   <b-avatar
				   rounded
				   variant="light-primary"
			   >
				  <feather-icon
					  icon="TrendingUpIcon"
					  size="18"
				  />
			   </b-avatar>
			   <div class="ml-1">
				  <h4 class="mb-0">
					 €{{ convertFormatPrice(contact.all_years_tziros) }}

				  </h4>
				  <small> {{ $t('generic.profit') }}</small>
			   </div>
			</div>

			<div class="d-flex align-items-center mr-2">
			   <b-avatar
				   rounded
				   variant="light-success"
			   >
				  <feather-icon
					  icon="UserCheckIcon"
					  size="18"
				  />
			   </b-avatar>
			   <div class="ml-1">
				  <h4 class="mb-0">
					 €{{ convertFormatPrice(contact.all_years_payments) }}
				  </h4>
				  <small>{{ $t('generic.payments') }}</small>
			   </div>
			</div>

			<div class="d-flex align-items-center">
			   <b-avatar
				   rounded
				   variant="light-danger"
			   >
				  <feather-icon
					  icon="UserMinusIcon"
					  size="18"
				  />
			   </b-avatar>
			   <div class="ml-1">
				  <h4 class="mb-0">
					 €{{ getSum(contact.all_years_tziros, contact.all_years_payments) }}
				  </h4>
				  <small>{{ $t('generic.balance') }}</small>
			   </div>
			</div>
		 </div>

		 <vue-apex-charts
			 :options="revenueComparisonLine.chartOptions"
			 :series="revenueComparisonLine.series"
			 height="150"
			 type="line"
		 />
	  </b-col>
   </b-row>
</template>

<script>
import {BAvatar, BCardText, BCol, BRow} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import {$themeColors} from '@themeConfig'
import CurrencyMixin from "@/Mixins/CurrencyMixin";
import _ from "lodash";

export default {
   components: {
	  BRow,
	  BCol,
	  BCardText,
	  BAvatar,
	  VueApexCharts,
   },
   props: {
	  contact: [Object, Array],
   },
   mixins: [CurrencyMixin],
   data() {
	  return {
		 revenue: {},
		 revenueComparisonLine: {
			series: [],
			chartOptions: {
			   chart: {
				  toolbar: {show: false},
				  zoom: {enabled: false},
				  type: 'line',
				  offsetX: -10,
			   },
			   stroke: {
				  curve: 'smooth',
				  dashArray: [0, 12],
				  width: [4, 3],
			   },
			   legend: {
				  show: false,
			   },
			   colors: ['#d0ccff', '#ebe9f1'],
			   fill: {
				  type: 'gradient',
				  gradient: {
					 shade: 'dark',
					 inverseColors: false,
					 gradientToColors: [$themeColors.primary, '#ebe9f1'],
					 shadeIntensity: 1,
					 type: 'horizontal',
					 opacityFrom: 1,
					 opacityTo: 1,
					 stops: [0, 100, 100, 100],
				  },
			   },
			   markers: {
				  size: 0,
				  hover: {
					 size: 5,
				  },
			   },
			   xaxis: {
				  labels: {
					 style: {
						colors: '#b9b9c3',
						fontSize: '1rem',
					 },
				  },
				  axisTicks: {
					 show: false,
				  },
				  categories: ['Ιαν', 'Φεβ', 'Μαρ', 'Απρ', 'Μαϊ', 'Ιουν', 'Ιουλ', 'Αυγ', 'Σεπ', 'Οκτ', 'Νοε', '∆εκ'],
				  axisBorder: {
					 show: false,
				  },
				  tickPlacement: 'on',
			   },
			   yaxis: {
				  tickAmount: 5,
				  labels: {
					 style: {
						colors: '#b9b9c3',
						fontSize: '1rem',
					 },
					 formatter(val) {
						return new Intl.NumberFormat('de-DE', {
						   style: 'currency',
						   currency: 'EUR',
						   currencyDisplay: "code"
						}).format(val)
							.replace("EUR", "")
							.trim();
					 },
				  },
			   },
			   grid: {
				  borderColor: '#e7eef7',
				  padding: {
					 top: -20,
					 bottom: -10,
					 left: 20,
				  },
			   },
			   tooltip: {
				  x: {show: false},
			   },
			},
		 },
	  }
   },
   mounted() {
   },
   methods: {
	  getSum(tziros, paid) {
		 const result = tziros - paid
		 return this.convertFormatPrice(result)
	  }
   },
   watch: {
	  contact(n, o) {
		 let payment = _.mapValues(n.graph, v => v.payments);
		 let services = _.mapValues(n.graph, v => v.services);


		 this.revenueComparisonLine.series = [
			{
			   name: 'Εσοδα',
			   data: _.values(services),
			   // data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2],
			},
			{
			   name: 'Πληρωμές',
			   data: _.values(payment),
			},
		 ]
	  }
   },
   created() {
	  this.$http.get('/card/card-analytics/revenue').then(res => {
		 this.revenue = res.data
	  })
   },

}
</script>

<style lang="scss"
	   scoped>
@import '@core/scss/vue/libs/chart-apex.scss';


.b-avatar {
   width: 3rem;
   height: 3rem;
}
</style>

