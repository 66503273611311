<template>
   <b-sidebar
	   id="sidebar-payment"
	   :visible="false"
	   backdrop
	   bg-variant="white"
	   no-header
	   right
	   shadow
	   sidebar-class="sidebar-lg"
   >
	  <template #default="{ hide }">
		 <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
			<h5 class="mb-0">
			   {{ showPaymentEdit ? $t('projects.edit-payment') : $t('projects.add-payment') }}
			</h5>
			<feather-icon
				class="ml-1 cursor-pointer"
				icon="XIcon"
				size="16"



				@click.prevent="closeModal"
			/>
		 </div>

		 <b-form class="p-2"
				 @submit.prevent="onSubmitPayments">
			<!-- start::select contact -->
			<b-form-group :label="$t('generic.client')"
						  label-for="payment-project">
			   <b-form-input :value="getName"
							 disabled
							 placeholder="0.00,00" />
			</b-form-group>
			<!-- end::select calendar -->

			<!-- start::select calendar -->
			<b-form-group :label="$t('generic.project')"
						  label-for="payment-project">
			   <b-form-input :value="$store.getters.getProject.title"
							 disabled
							 placeholder="0.00,00" />
			</b-form-group>
			<!-- end::select calendar -->

			<!-- start::Date -->
			<b-form-group :label="$t('generic.date')"
						  class="required"
						  label-for="Payment Date">

			   <date-picker
				   v-model="pay.date"
				   :show-time-header="true"
				   class="w-100"
				   format="DD-MM-YYYY"
				   type="date"
				   valueType="YYYY-MM-DD" />
			   <b-form-invalid-feedback>Validation</b-form-invalid-feedback>
			</b-form-group>
			<!-- end::Date -->

			<!-- start::Payment Method -->
			<b-form-group
				:label="$t('generic.payment-method')"
				label-for="payment-method"
			>
			   <v-select
				   v-model="pay.status"
				   :options="$store.getters.getPayment"
				   :reduce="val => val.value"
				   input-id="payment-method"
				   label="text"

			   >
				  <template #option="{ text }">
					 <span> {{ text }}</span>
				  </template>
				  <template #selected-option="{ text }">
					 <span> {{ text }}</span>
				  </template>
			   </v-select>
			</b-form-group>
			<!-- end::Payment Method -->

			<!-- start::ammount -->
			<b-form-group :label="$t('generic.ammount')"
						  label-for="payment-ammount">
			   <input ref="price"
					  v-model="pay.price"
					  class="form-control"
					  @blur="convertCurrency(pay)"
					  @focus="revertCurrency(pay)"
			   />


			</b-form-group>
			<!-- end::ammount -->

			<!-- start::notes -->
			<b-form-group :label="$t('contacts.notes')"
						  label-for="payment-description">
			   <b-form-textarea id="payment-description"
								v-model="pay.notes" />
			</b-form-group>
			<!-- end::notes -->

			<!-- start::form actions -->
			<div class="d-flex mt-2">
			   <b-button class="mr-2"
						 type="submit"
						 variant="primary">
				  {{ !showPaymentEdit ? $t('generic.add') : $t('generic.save') }}


			   </b-button>
			   <b-button
				   variant="outline-secondary"
				   @click="hide">
				  {{ $t('generic.close') }}
			   </b-button>
			</div>
			<!-- end::form actions -->

		 </b-form>

	  </template>
   </b-sidebar>
</template>

<script>
import {
   BAvatar,
   BButton,
   BForm,
   BFormCheckbox,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BFormTextarea,
   BSidebar
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import FormMixin from "@/Mixins/FormMixin";
import ToastMixin from "@/Mixins/ToastMixin";
import _ from "lodash";
import {Money} from "v-money";
import moment from "moment";

const guestsOptions = [
   {avatar: require('@/assets/images/avatars/1-small.png'), name: 'Jane Foster'},
   {avatar: require('@/assets/images/avatars/3-small.png'), name: 'Donna Frank'},
   {avatar: require('@/assets/images/avatars/5-small.png'), name: 'Gabrielle Robertson'},
   {avatar: require('@/assets/images/avatars/7-small.png'), name: 'Lori Spears'},
   {avatar: require('@/assets/images/avatars/9-small.png'), name: 'Sandy Vega'},
   {avatar: require('@/assets/images/avatars/11-small.png'), name: 'Cheryl May'},
]

export default {
   components: {
	  BSidebar,
	  BForm,
	  Money,
	  BFormGroup,
	  BFormInput,
	  BFormInvalidFeedback,
	  BFormCheckbox,
	  BAvatar,
	  BFormTextarea,
	  BButton,
	  vSelect,
   },
   props: {
	  paymentEdit: Object,
	  formRef: Object
   },
   mixins: [FormMixin, ToastMixin],
   data() {

	  return {
		 money: {
			decimal: ',',
			thousands: '.',
			prefix: '',
			suffix: '',
			precision: 2,
			masked: false
		 },
		 pay: {
			"reference": null,
			"contactId": null,
			"projectId": null,
			"status": null,
			"price": 0,
			"date": '2022-10-04',
			"notes": null,
			"discount": null,
			edit: false,
		 },
		 user: {
			name: null,
			surname: null,

		 },
		 contact: [],
		 guestsOptions: guestsOptions,
		 selectedPaymentMethod: ['Μετρητά'],
	  }
   },
   watch: {
	  paymentEdit() {
		 if (this.paymentEdit) {
			let clonePayEdit = _.cloneDeep(this.paymentEdit);
			this.pay = _.cloneDeep(clonePayEdit);
			this.convertCurrency(this.pay)
		 } else {
			this.pay = {
			   "reference": null,
			   "contactId": null,
			   "projectId": null,
			   "status": null,
			   "price": 0,
			   "date": '2022-10-04',
			   "notes": null,
			   "discount": null,
			   edit: false,
			}
		 }

	  },
   },
   methods: {
	  closeModal() {
		 this.$root.$emit('bv::toggle::collapse', 'sidebar-payment')
	  },

	  revertCurrency(index) {
		 this.pay.price = this.parseLocaleNumber(index.price, 'de');
	  },
	  convertCurrency(index) {
		 this.pay.price = new Intl.NumberFormat('de-DE', {
			style: 'currency',
			currency: 'EUR',
			currencyDisplay: "code"
		 }).format(index.price)
			 .replace("EUR", "")
			 .trim();

	  },
	  parseLocaleNumber(stringNumber, locale) {
		 var thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
		 var decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

		 return parseFloat(stringNumber
			 .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
			 .replace(new RegExp('\\' + decimalSeparator), '.')
		 );
	  },
	  onSubmitPayments() {


		 this.pay.contactId = this.$store.getters.getProject.contactId
		 this.pay.projectId = this.$store.getters.getProject.id
		 if (this.pay.price === undefined) {
			this.pay.price = 0
		 }


		 if (this.paymentEdit === null || this.paymentEdit.edit === false) {
			this.pay.reference = moment().valueOf().toString().substring(5, 10);
			let clonePay = _.cloneDeep(this.pay);
			clonePay.price = this.parseLocaleNumber(clonePay.price, 'de');

			this.$emit('getListOfPayment', clonePay);

			this.$emit('dirtyForm');
			this.resetField(this.pay);
		 } else {
			let clonePay = _.cloneDeep(this.pay);
			clonePay.price = this.parseLocaleNumber(clonePay.price, 'de');
			this.pay.reference = this.paymentEdit?.reference
			this.$emit('getListOfPayment', clonePay);


			this.resetField(this.pay);
		 }
		 this.$emit('onSave');
		 // this.$emit("submit");
		 this.paymentEdit.edit = false;

		 // this.$emit('getPay', this.pay);


	  },
   },


   computed: {
	  calendars() {
		 return this.$store.getters.getProject.title
	  },
	  showPaymentEdit() {
		 return this.paymentEdit?.reference
	  },

	  getName() {
		 return this.$store.getters.getProject.contact?.name + ' ' + this.$store.getters.getProject.contact?.surname
	  },


   },
   async created() {


	  this.$emit('getPay', this.pay);

	  // await this.$store.dispatch("fetchProject", this.$route.params.projectId)


	  // console.log(this.contactId)

	  if (this?.formRef?.contactId)
		 await this.$store.dispatch("fetchContact", this.formRef.contactId)


   }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>


