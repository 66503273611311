var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-send-new-mail","visible":false,"backdrop":"","bg-variant":"white","no-header":"","right":"","shadow":"","sidebar-class":"sidebar-lg"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Αποστολή e-mail ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"}})],1),_c('b-form',{staticClass:"p-2"},[_c('b-form-group',{attrs:{"label":_vm.$t('generic.client'),"label-for":"mail-recepient"}},[_c('v-select',{attrs:{"options":_vm.contact},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var surname = ref.surname;
return [_c('div',{staticClass:"rounded-circle d-inline-block ",staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(name)+" "+_vm._s(surname))])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var surname = ref.surname;
return [_c('div',{staticClass:"rounded-circle d-inline-block ",staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(name)+" "+_vm._s(surname))])]}}],null,true)},[_c('b-form-invalid-feedback',[_vm._v("Validation")])],1)],1),_c('b-form-group',{attrs:{"label":"Θέμα μηνύματος","label-for":"mail-subject"}},[_c('b-form-input',{attrs:{"id":"mail-subject","placeholder":""}})],1),_c('b-form-group',{attrs:{"label":"Μήνυμα","label-for":"mail-body"}},[_c('quill-editor',{staticClass:"border-bottom-0",attrs:{"id":"quil-content","options":_vm.editorOption}}),_c('div',{staticClass:"d-flex justify-content-end border-top-0",attrs:{"id":"quill-toolbar"}},[_c('button',{staticClass:"ql-bold"}),_c('button',{staticClass:"ql-italic"}),_c('button',{staticClass:"ql-underline"}),_c('button',{staticClass:"ql-align"}),_c('button',{staticClass:"ql-link"})])],1),_c('b-form-checkbox',{attrs:{"value":"A"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._v(" Να σταλεί αντίγραφο και σε εμένα ")]),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Αποστολή ")]),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Ακύρωση ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }