var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"sidebar-project-database","visible":false,"backdrop":"","bg-variant":"white","no-header":"","right":"","shadow":"","sidebar-class":"sidebar-lg"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){$event.preventDefault();return _vm.closeModal($event)}}})],1),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('validation-provider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Τίτλος","label-for":"project-title"}},[_c('b-form-input',{attrs:{"id":"project-title","state":_vm.getValidationState(validationContext),"placeholder":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),(validationContext.errors[0])?_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(_vm.$t(_vm.getError(validationContext.errors[0])))+" ")]):_vm._e()],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Κατηγορία","label-for":"project-category"}},[_c('v-select',{attrs:{"options":_vm.allProjects,"reduce":function (item){ return item.id; },"label":"title","placehoder":"Επιλέξτε..."},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(_vm._s(_vm.$t('errors.no-matching')))]},proxy:true}],null,true),model:{value:(_vm.form.categoryId),callback:function ($$v) {_vm.$set(_vm.form, "categoryId", $$v)},expression:"form.categoryId"}})],1),_c('validation-provider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"state":_vm.getValidationState(validationContext),"label":"Κατάσταση","label-for":"project-status"}},[_c('v-select',{attrs:{"options":_vm.calendars,"reduce":function (item){ return item.label; }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(_vm._s(_vm.$t('errors.no-matching')))]},proxy:true},{key:"option",fn:function(ref){
var color = ref.color;
var label = ref.label;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:("bg-" + color),staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
var color = ref.color;
var label = ref.label;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:("bg-" + color),staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[_c('b-form-invalid-feedback',[_vm._v("Validation")])],1)],1),_c('b-form-invalid-feedback',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)}),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Ημ/νία έναρξης","label-for":"birth-date"}},[_c('date-picker',{staticClass:"w-100",attrs:{"show-time-header":true,"format":"DD-MM-YYYY","type":"date","valueType":"YYYY-MM-DD"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Deadline","label-for":"birth-date"}},[_c('date-picker',{staticClass:"w-100",attrs:{"show-time-header":true,"format":"DD-MM-YYYY","type":"date","valueType":"YYYY-MM-DD"},model:{value:(_vm.form.deadline),callback:function ($$v) {_vm.$set(_vm.form, "deadline", $$v)},expression:"form.deadline"}})],1)],1)],1),(!_vm.$route.params.hasOwnProperty('projectId'))?_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"disabled":!_vm.form.title,"type":"submit","variant":"primary"}},[_vm._v(" Αποθήκευση ")]),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Κλείσιμο ")])],1):_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.submit}},[_vm._v(" Αποθήκευση ")]),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Κλείσιμο ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }