<!--NOTE: πρέπει να γίνει global component, θα το έχουμε κ σε άλλα σημεία στο app -->

<template>
   <div>
	  <b-sidebar id="sidebar-send-new-mail"
				 :visible="false"
				 backdrop
				 bg-variant="white"
				 no-header
				 right
				 shadow
				 sidebar-class="sidebar-lg">
		 <template #default="{ hide }">
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
			   <h5 class="mb-0">
				  Αποστολή e-mail
			   </h5>
			   <feather-icon
				   class="ml-1 cursor-pointer"
				   icon="XIcon"
				   size="16"

			   />
			</div>

			<b-form class="p-2">

			   <!-- start::select contact -->
			   <!-- όταν είσαι μέσα στην καρτέλα του πελάτη αυτό να είναι προεπιλγμένο με το όνομά του -->
			   <b-form-group :label="$t('generic.client')"
							 label-for="mail-recepient">
				  <v-select :options="contact">


					 <template #option="{ name, surname }">
						<div class="rounded-circle d-inline-block "
							 style="height:10px;width:10px" />
						<span> {{ name }} {{ surname }}</span>
					 </template>

					 <template #selected-option="{ name, surname }">
						<div class="rounded-circle d-inline-block "
							 style="height:10px;width:10px" />
						<span> {{ name }} {{ surname }}</span>
					 </template>

					 <b-form-invalid-feedback>Validation</b-form-invalid-feedback>

				  </v-select>
			   </b-form-group>
			   <!-- end::select contact -->

			   <!-- start::ammount -->
			   <b-form-group label="Θέμα μηνύματος"
							 label-for="mail-subject">
				  <b-form-input id="mail-subject"
								placeholder="" />
			   </b-form-group>
			   <!-- end::ammount -->

			   <!-- start::notes -->
			   <b-form-group label="Μήνυμα"
							 label-for="mail-body">
				  <quill-editor id="quil-content"
								:options="editorOption"
								class="border-bottom-0" />
				  <div id="quill-toolbar"
					   class="d-flex justify-content-end border-top-0">
					 <!-- Add a bold button -->
					 <button class="ql-bold" />
					 <button class="ql-italic" />
					 <button class="ql-underline" />
					 <button class="ql-align" />
					 <button class="ql-link" />
				  </div>
			   </b-form-group>
			   <!-- end::notes -->

			   <b-form-checkbox
				   v-model="selected"
				   value="A"
			   >
				  Να σταλεί αντίγραφο και σε εμένα
			   </b-form-checkbox>

			   <!-- start::form actions -->
			   <div class="d-flex mt-2">
				  <b-button class="mr-2"
							type="submit"
							variant="primary">
					 Αποστολή
				  </b-button>
				  <b-button type="reset"
							variant="outline-secondary">
					 Ακύρωση
				  </b-button>
			   </div>
			   <!-- end::form actions -->

			</b-form>

		 </template>
	  </b-sidebar>
   </div>
</template>

<script>

const editorOption = {
   modules: {
	  toolbar: '#quill-toolbar',
   },
   placeholder: 'Write your description',
}

import {BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormInvalidFeedback, BSidebar} from 'bootstrap-vue';
import vSelect from 'vue-select'
import {quillEditor} from 'vue-quill-editor'


export default {
   components: {
	  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormCheckbox,
	  BButton, vSelect, quillEditor
   },
   props: {
	  contact: Array
   },
   data() {
	  return {
		 editorOption: editorOption,
	  }
   },


}
</script>

<style lang="scss"
	   scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '~@core/scss/base/bootstrap-extended/include';


.assignee-selector {
   ::v-deep .vs__dropdown-toggle {
	  padding-left: 0;
   }
}


#quil-content ::v-deep {
   > .ql-container {
	  border-bottom: 0;
   }

   + #quill-toolbar {
	  border-top-left-radius: 0;
	  border-top-right-radius: 0;
	  border-bottom-left-radius: $border-radius;
	  border-bottom-right-radius: $border-radius;
   }
}

</style>