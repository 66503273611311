<template>
   <b-sidebar
	   id="sidebar-project-database"
	   :visible="false"
	   backdrop
	   bg-variant="white"
	   no-header
	   right
	   shadow
	   sidebar-class="sidebar-lg"
   >
	  <template #default="{ hide }">
		 <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
			<h5 class="mb-0">
			   {{ title }}
			</h5>
			<feather-icon
				class="ml-1 cursor-pointer"
				icon="XIcon"
				size="16"
				@click.prevent="closeModal"
			/>
		 </div>
		 <validation-observer ref="observer"
							  v-slot="{handleSubmit , invalid}">
			<b-form class="p-2"
					@submit.prevent="submit">

			   <!-- start::title -->
			   <validation-provider v-slot="validationContext"
									:rules="{ required: true }">
				  <b-form-group class="required"
								label="Τίτλος"
								label-for="project-title">
					 <b-form-input id="project-title"
								   v-model="form.title"
								   :state="getValidationState(validationContext)"
								   placeholder="" />
					 <b-form-invalid-feedback v-if="validationContext.errors[0]">
						{{ $t(getError(validationContext.errors[0])) }}
					 </b-form-invalid-feedback>
				  </b-form-group>
			   </validation-provider>
			   <!-- end::title -->

			   <!-- start::select contact -->
			   <!-- όταν είσαι μέσα στην καρτέλα του πελάτη αυτό να είναι προεπιλγμένο με το όνομά του ή να είναι κρυφό, ότι σε βολεύει -->
			   <!--			   <validation-provider :rules="{ required: true }"-->
			   <!--									v-slot="validationContext">-->
			   <!--				  <b-form-group label="Επαφή"-->
			   <!--								:state="getValidationState(validationContext)"-->
			   <!--								label-for="mail-recepient">-->
			   <!--				  </b-form-group>-->
			   <!--				  <b-form-invalid-feedback style="margin-top: -5px"> {{-->
			   <!--						validationContext.errors[0]-->
			   <!--																	 }}-->
			   <!--				  </b-form-invalid-feedback>-->
			   <!--			   </validation-provider>-->
			   <!-- end::select contact -->

			   <!-- start::project category -->

			   <!-- Τις φέρνει από τις Ρυθμίσεις -->
			   <b-form-group
				   label="Κατηγορία"
				   label-for="project-category"
			   >
				  <v-select
					  v-model="form.categoryId"
					  :options="allProjects"
					  :reduce="item=> item.id"
					  label="title"
					  placehoder="Επιλέξτε..."
				  >
					 <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
				  </v-select>
			   </b-form-group>
			   <!-- end::project category -->

			   <!-- start::project status -->
			   <validation-provider v-slot="validationContext"
									:rules="{ required: true }">
				  <b-form-group :state="getValidationState(validationContext)"
								label="Κατάσταση"
								label-for="project-status">
					 <v-select
						 v-model="form.status"
						 :options="calendars"
						 :reduce="item=> item.label">
						<template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
						<template #option="{ color, label }">
						   <div :class="`bg-${color}`"
								class="rounded-circle d-inline-block mr-50"
								style="height:10px;width:10px" />
						   <span> {{ label }}</span>
						</template>

						<template #selected-option="{ color, label }">
						   <div :class="`bg-${color}`"
								class="rounded-circle d-inline-block mr-50"
								style="height:10px;width:10px" />
						   <span> {{ label }}</span>
						</template>

						<b-form-invalid-feedback>Validation</b-form-invalid-feedback>

					 </v-select>
				  </b-form-group>
				  <b-form-invalid-feedback class="mb-2">
					 {{ validationContext.errors[0] }}
				  </b-form-invalid-feedback>
			   </validation-provider>
			   <!-- end::project status -->

			   <b-row>
				  <b-col md="6">
					 <!-- start::project-start-date -->
					 <b-form-group
						 label="Ημ/νία έναρξης"
						 label-for="birth-date"
					 >
						<date-picker

							v-model="date"
							:show-time-header="true"
							class="w-100"
							format="DD-MM-YYYY"
							type="date"
							valueType="YYYY-MM-DD" />

					 </b-form-group>
					 <!-- end::project-start-date -->
				  </b-col>
				  <b-col md="6">
					 <!-- start::project-Deadline -->
					 <b-form-group
						 label="Deadline"
						 label-for="birth-date"
					 >
						<date-picker
							v-model="form.deadline"
							:show-time-header="true"
							class="w-100"
							format="DD-MM-YYYY"
							type="date"
							valueType="YYYY-MM-DD" />

					 </b-form-group>
					 <!-- end::project-Deadline -->
				  </b-col>
			   </b-row>

			   <!-- start::form actions -->
			   <div v-if="!$route.params.hasOwnProperty('projectId')"
					class="d-flex mt-2">
				  <b-button :disabled="!form.title "
							class="mr-2"
							type="submit"
							variant="primary">
					 Αποθήκευση
				  </b-button>
				  <b-button type="reset"
							variant="outline-secondary"
							@click="hide">
					 Κλείσιμο
				  </b-button>
			   </div>
			   <div v-else
					class="d-flex mt-2">
				  <b-button class="mr-2"
							type="submit"
							variant="primary"
							@click="submit">
					 Αποθήκευση
				  </b-button>
				  <b-button type="reset"
							variant="outline-secondary"
							@click="hide">
					 Κλείσιμο
				  </b-button>
			   </div>

			   <!-- end::form actions -->

			</b-form>
		 </validation-observer>

	  </template>
   </b-sidebar>
</template>

<script>

import {
   BButton,
   BCol,
   BForm,
   BFormCheckbox,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BRow,
   BSidebar,
} from 'bootstrap-vue';
import vSelect from 'vue-select'
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import moment from "moment";

extend('required', {
   ...required,
   message: 'Υποχρεωτικό πεδίο'

})

export default {
   props: {
	  props:Number,
	  isDisabled: Boolean,
	  form: Object,
	  allProjects: Array,
	  allProjectAll: Array,
	  allCategories: Array,
	  title: String
   },
   components: {
	  BRow, BCol, BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox,
	  BButton,
	  BFormInvalidFeedback,
	  vSelect,
	  ValidationObserver,
	  ValidationProvider,
   },
   data() {
	  return {
		 formValue: this.form,
		 selectedProjectCategories: {title: 'Web'},
		 projectCategories: [{title: 'Web'}, {title: 'Printworks'}, {title: 'Digital'}],
		 date:moment().format('YYYY-MM-DD'),


	  }
   },
   methods: {
	  closeModal() {
		 this.$root.$emit('bv::toggle::collapse', 'sidebar-project-database')
	  },
	  refresh(){
		 // this.$emit('refresh')

		 this.form.startDate = moment().format('YYYY-MM-DD');

	  },
	  submit() {
		 this.form.startDate = this.date
		 this.$emit('submit', this.form)
		 this.$refs.observer.reset();
	  },
	  isValidSave() {
	  },
	  getValidationState({dirty, validated, valid = null}) {
		 return validated ? valid : null;
	  },
	  getError(error) {
		 return `errors.${error.toLowerCase().replaceAll(' ', '-').replace('.', '')}`
	  },
   },
   computed: {
	  calendars() {
		 return this.$store.getters.getCalendars
	  },

   },
   async created() {
	  // this.form.startDate = moment().format('YYYY-MM-DD')
	  // const responseCategory = await axiosIns.get(`/contact-category`, {
	  //  params: {
	  // 	rpp: 100
	  //  }
	  // })
	  // this.allCategories = responseCategory.data.data


   }
}
</script>

<style lang="scss"
	   scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/bootstrap-extended/include';


.assignee-selector {
   ::v-deep .vs__dropdown-toggle {
	  padding-left: 0;
   }
}


</style>