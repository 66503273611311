<template>
   <b-modal
	   id="compose-mail"
	   :visible="shallShowEmailComposeModal"
	   body-class="p-1"
	   footer-class="d-flex justify-content-between"
	   hide-backdrop
	   modal-class="modal-sticky"
	   no-fade
	   size="lg"
	   static
	   title="Compose Email"
	   @change="(val) => $emit('update:shall-show-email-compose-modal', val)"
   >
	  <!-- Modal Header -->
	  <template #modal-header>
		 <h5 class="modal-title">
			{{ $t('settings.reminder-balance') }}
		 </h5>
		 <div class="modal-actions">
			<feather-icon
				class="cursor-pointer"
				icon="MinusIcon"
				@click="$bvModal.hide('compose-mail')"
			/>
			<!--			<feather-icon-->
			<!--				icon="Maximize2Icon"-->
			<!--				class="ml-1 cursor-pointer"-->
			<!--			/>-->
			<feather-icon
				class="ml-1 cursor-pointer"
				icon="XIcon"
				@click="discardEmail,$bvModal.hide('compose-mail')"
			/>
		 </div>
	  </template>

	  <!-- Modal Footer -->
	  <template #modal-footer>
		 <!-- Footer: Left Content -->
		 <div>
			<b-button
				v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				right
				split
				variant="primary"
				@click="sendEmail"
			>
			   {{ $t('generic.send') }}
			</b-button>
		 </div>

		 <!-- Footer: Right Content -->
		 <div>


		 </div>
	  </template>

	  <!-- Modal: Body -->
	  <b-form>

		 <!-- Field: To -->
		 <div class="compose-mail-form-field">
			<label
				class="form-label"
				for="email-to"
			>{{ $t('generic.to') }}: </label>
			<b-form-input :disabled="true"
						  :value="`${$store.getters.getContact.name} ${$store.getters.getContact.surname}  (${getMail($store.getters.getContact.email)})`  "
						  placeholder="John" />


		 </div>

		 <!-- Field: Cc (Hidden Initially) -->
		 <div
			 v-show="showCcField"
			 class="compose-mail-form-field"
		 >
			<label for="email-cc">CC: </label>
			<v-select
				v-model="composeData.cc"
				:close-on-select="false"
				:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
				:options="emailToOptions"
				class="flex-grow-1 email-cc-selector"
				input-id="email-cc"
				label="name"
				multiple
			>
			   <template #option="{ name, surname }">
				  <div class="rounded-circle d-inline-block "
					   style="height:10px;width:10px" />
				  <span> {{ name }} {{ surname }}</span>
			   </template>

			   <template #selected-option="{ name, surname }">
				  <div class="rounded-circle d-inline-block "
					   style="height:10px;width:10px" />
				  <span> {{ name }} {{ surname }}</span>
			   </template>
			   <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
			</v-select>
		 </div>

		 <!-- Field: Bcc (Hidden Initially) -->
		 <div
			 v-show="showBccField"
			 class="compose-mail-form-field"
		 >
			<label for="email-bcc">Bcc </label>
			<v-select
				v-model="composeData.bcc"
				:close-on-select="false"
				:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
				:options="emailToOptions"
				class="flex-grow-1 email-bcc-selector"
				input-id="email-bcc"
				label="name"
				multiple
			>
			   <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
			   <template #option="{ avatar, name }">
				  <b-avatar
					  :src="avatar"
					  size="sm"
				  />
				  <span class="ml-50"> {{ name }}</span>
			   </template>

			   <template #selected-option="{ avatar, name }">
				  <b-avatar
					  :src="avatar"
					  class="border border-white"
					  size="sm"
				  />
				  <span class="ml-50"> {{ name }}</span>
			   </template>
			</v-select>
		 </div>

		 <!-- Field: Subject -->
		 <div class="compose-mail-form-field">
			<label for="email-subject">{{ $t('generic.subject') }}: </label>
			<b-form-input
				id="email-subject"
				v-model="composeData.title"
			/>
		 </div>

		 <!-- Field: Message - Quill Editor -->
		 <div class="message-editor">
			<quill-editor
				id="quil-content"
				v-model="composeData.content"
				:options="editorOption"
			/>
			<div
				id="quill-toolbar"
				class="d-flex border-bottom-0"
			>


			</div>
		 </div>
	  </b-form>

   </b-modal>
</template>

<script>
import {BAvatar, BButton, BDropdown, BDropdownDivider, BDropdownItem, BForm, BFormInput,} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ref} from '@vue/composition-api'
import {quillEditor} from 'vue-quill-editor'
import vSelect from 'vue-select'
import store from "@/store";
import axiosIns from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ToastMixin from "@/Mixins/ToastMixin";


export default {
   directives: {
	  Ripple,
   },
   data() {
	  return {
		 show: true
	  }
   },
   props: {
	  contact: Array,
	  shallShowEmailComposeModal: {
		 type: Boolean,
		 required: true,
	  },
   },
   model: {
	  prop: 'shallShowEmailComposeModal',
	  event: 'update:shall-show-email-compose-modal',
   },
   mixins: [ToastMixin],
   methods: {
	  getMail(value) {
		 if (value) {
			return value
		 }


		 return 'mail'
	  }
   },
   async created() {
	  window.swall = this.$swal;
	  window.toast = this.$toast;

	  // await this.$store.dispatch("fetchTodos");
   },
   components: {
	  BButton,
	  // BSV
	  BDropdown,
	  BDropdownItem,
	  BForm,
	  BFormInput,
	  BAvatar,
	  BDropdownDivider,

	  // 3rd Party
	  quillEditor,
	  vSelect,
   },
   setup(_, {emit}) {
	  const composeData = ref({content: ''})
	  const showCcField = ref(false)
	  const showBccField = ref(false)


	  store.dispatch('fetchMail').then((r) => {
		 composeData.value['content'] = store.getters.getMail.content;
		 composeData.value['title'] = `Υπενθύμιση υπολοίπου - ${JSON.parse(localStorage.getItem('userData')).surname} ${JSON.parse(localStorage.getItem('userData')).name}`;

	  })


	  const editorOption = {
		 modules: {
			toolbar: '#quill-toolbar',
		 },
		 placeholder: 'Μήνυμα',
	  }
	  const notify = (title, variant = 'success', text = '') => {
		 window.toast({
			component: ToastificationContent,
			position: 'top-right',
			props: {
			   title: title,
			   icon: 'CheckIcon',
			   variant: variant,
			   text: text,
			},
		 })
	  }
	  /* eslint-disable global-require */
	  const emailToOptions = [
		 {avatar: require('@/assets/images/avatars/1-small.png'), name: 'Jane Foster'},
		 {avatar: require('@/assets/images/avatars/3-small.png'), name: 'Donna Frank'},
		 {avatar: require('@/assets/images/avatars/5-small.png'), name: 'Gabrielle Robertson'},
		 {avatar: require('@/assets/images/avatars/7-small.png'), name: 'Lori Spears'},
		 {avatar: require('@/assets/images/avatars/9-small.png'), name: 'Sandy Vega'},
		 {avatar: require('@/assets/images/avatars/11-small.png'), name: 'Cheryl May'},
	  ]
	  /* eslint-enable global-require */

	  const sendEmail = () => {
		 composeData.value.contactId = window.location.href.split('/').pop()
		 store.dispatch('sendMail', composeData.value)

		 composeData.value.title = ''
		 notify(`Στάλθηκε`)

		 emit('update:shall-show-email-compose-modal', false)

		 // ? Send your Email
	  }

	  const discardEmail = () => {
		 composeData.value = {}
		 // this.$root.$emit("bv::hide::modal", "modal", );

		 // $bvModal.hide('compose-mail')
	  }

	  return {
		 composeData,
		 editorOption,
		 emailToOptions,
		 showCcField,
		 showBccField,

		 // Email actions
		 sendEmail,
		 discardEmail,
	  }
   },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/base/pages/app-email.scss';
</style>

<style lang="scss"
	   scoped>
form ::v-deep {

   // Mail To vue-select style
   .v-select {
	  .vs__dropdown-toggle {
		 border: 0;
		 box-shadow: none;
	  }

	  .vs__open-indicator {
		 display: none;
	  }
   }

   // Quill Editor Style
   .quill-editor {
	  .ql-container.ql-snow {
		 border-bottom: 0 !important;
	  }
   }
}
</style>
