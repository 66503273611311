<template>
   <b-row class="align-items-center">
	  <b-col
		  class="d-flex justify-content-between flex-column"
		  cols="12"
		  md="8">

		 <div class="d-flex justify-content-start align-items-center">
			<b-avatar
				v-if="contact.photo"
				ref="previewEl"
				:src="contact.photo"
				rounded
				size="60px"
				variant="light"
			/>
			<b-avatar v-else
					  :text="fullName"
					  :variant="getColor"
					  rounded
					  size="60px"
			/>
			<div class="d-flex flex-column ml-2">
			   <div class="mb-0">
				  <h4 class="mb-50">
					 {{ contact.surname }} {{ contact.name }}
				  </h4>
				  <span class="card-text">
                    <span class="mr-1">
                        <span class="text-primary font-weight-bolder">#</span>
                       {{ contact.id }}
                    </span>
                    <b-badge v-if="contact.category"
							 pill
							 variant="light-warning">
                        {{ contact.category.title }}
                    </b-badge>
                </span>
			   </div>
			</div>
		 </div>

	  </b-col>
	  <b-col cols="12"
			 md="4">
		 <div class="d-flex flex-wrap align-items-center justify-content-end">
			<b-dropdown
				class="chart-dropdown"
				no-caret
				right
				toggle-class="p-0"
				variant="link"
			>
			   <template #button-content>
				  <feather-icon
					  class="text-body cursor-pointer"
					  icon="MoreVerticalIcon"
					  size="18"
				  />
			   </template>
			   <b-dropdown-group header="Πρόσθεσε">
				  <b-dropdown-item v-b-toggle.sidebar-project-database>
					 <feather-icon icon="ClipboardIcon" />
					 <span class="align-middle ml-50">Νέο έργο</span>
				  </b-dropdown-item>
				  <b-dropdown-item v-b-toggle.sidebar-payment-database>
					 <feather-icon icon="CreditCardIcon" />
					 <span class="align-middle ml-50">Νέα πληρωμή</span>
				  </b-dropdown-item>
			   </b-dropdown-group>
			   <b-dropdown-group header="Στείλε">
				  <b-dropdown-item @click="hasEmail">
					 <feather-icon icon="MehIcon" />
					 <span class="align-middle ml-50">Υπενθύμιση υπολοίπου</span>
				  </b-dropdown-item>
			   </b-dropdown-group>
			   <b-dropdown-divider></b-dropdown-divider>
			   <b-dropdown-item @click="deleteContact($route.params.userId)">
				  <feather-icon class="text-danger"
								icon="TrashIcon" />
				  <span class="align-middle text-danger ml-50">Διαγραφή</span>
			   </b-dropdown-item>

			</b-dropdown>

			<b-button

				:to="{ name: 'contacts-edit', params: { userId: $route.params.userId } }"
				class="ml-2"
				variant="primary"
				@click="removeName"
			>
			   <feather-icon
				   class="mr-50"
				   icon="EditIcon"
			   />
			   <span class="align-middle">Επεξεργασία</span>
			</b-button>
		 </div>

	  </b-col>
   </b-row>
</template>

<script>
import {
   BAvatar,
   BBadge,
   BButton,
   BCol,
   BDropdown,
   BDropdownDivider,
   BDropdownGroup,
   BDropdownItem,
   BDropdownItemButton,
   BRow,
   VBToggle
} from 'bootstrap-vue'
import ToastMixin from "@/Mixins/ToastMixin";
import axiosIns from "@/libs/axios";
import {avatarText} from "@core/utils/filter";
import caldoSettings from "@/caldoSettings";

export default {
   components: {
	  BButton,
	  BRow,
	  BCol,
	  BAvatar,
	  BDropdown,
	  BDropdownItem,
	  BDropdownGroup,
	  BDropdownItemButton,
	  BDropdownDivider,
	  BBadge,
   },
   directives: {
	  "b-toggle": VBToggle,
   },
   mixins: [ToastMixin],
   props: {
	  contact: [Object, Array],
   },
   computed: {
	  fullName() {
		 return avatarText(this.contact.surname, this.contact.name)
	  },
	  getColor() {
		 return caldoSettings.getColor(this.contact.color)
	  },
   },
   methods: {
	  hasEmail() {

		 if (!this.contact.email) {
			this.notify('Παρακαλώ συμπληρώσετε το email', 'danger')
		 } else {
			this.$bvModal.show('compose-mail')
		 }
	  },
	  async deleteContact(id) {
		 const {isConfirmed} = await this.swal()
		 if (isConfirmed) {
			await axiosIns.delete(`/contact/${id}`)
				.then(response => {
				   this.$router.push({name: 'contacts'})
				   this.notify('Διαγράφηκε', `danger`)
				})
		 }
	  },
	  removeName() {
		 const test = document.querySelector('.breadcrumb')

		 let contain = test.lastChild.classList.contains('custom')
		 if (contain) {
			test.removeChild(test.lastChild);
		 }
	  }
   }

}
</script>

