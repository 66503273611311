<template>
   <div>
	  <b-alert variant="danger">
		 <h4 class="alert-heading">{{ $t('contacts.error') }}</h4>
		 <div class="alert-body">
			{{ $t('contacts.error-msg') }}
			<b-link class="alert-link"> {{ $t('contacts.contacts-list') }}</b-link>
			{{ $t('contacts.contacts-more') }}
		 </div>
	  </b-alert>

	  <b-card>
		 <user-view-user-info-header :contact="getContact" />
		 <hr>
		 <user-view-user-info-body :contact="getContact" />
	  </b-card>

	  <b-row class="match-height">
		 <b-col cols="12"
				md="6">
			<user-view-projects :key="num"
								:contact="getContact"></user-view-projects>
		 </b-col>
		 <b-col cols="12"
				md="6">
			<user-view-payments :key="num"
								:contact="getContact"></user-view-payments>
		 </b-col>
	  </b-row>

	  <!-- start: drawers -->
	  <sidebar-payment-database :all-projects="allProjectAll"
								:pay="pay"
								:projectInContact="projectAllInContact"
								@submitPay="onSubmitPay" />
	  <EmailCompose v-model="shallShowEmailComposeModal"
					:contact="$store.getters.getAllContacts" />
	  <!--	  <SendMail :contact="$store.getters.getAllContacts" />-->
	  <!--	  v-b-toggle.sidebar-project-->
	  <sidebar-project-database
		  @refresh="refresh"
		  :all-categories="allCategories"
		  :all-projects="allProject"
		  :form="form"
		  :key="count"
		  title="Νέο Έργο"
		  @submit="onSubmit" />

	  <!-- start::send new mail drawer -->
	  <!-- <div style="z-index: 1000" class="body-content-overlay show">
		  <send-mail></send-mail>
	  </div> -->
	  <!-- end::send new mail drawer -->

	  <!-- Compose Email Modal -->
	  <!--    <compose-mail v-model="shallShowEmailComposeModal" />-->

   </div>
</template>


<script>
import {BAlert, BCard, BCol, BLink, BRow, VBToggle} from "bootstrap-vue"
import UserViewUserInfoHeader from "./UserViewUserInfoHeader"
import UserViewUserInfoBody from "./UserViewUserInfoBody"
import UserViewProjects from "./UserViewProjects"
import UserViewPayments from "./UserViewPayments"
import SidebarPayment from '@/views/caldo-global/SidebarPayment'
import SendMail from '@/views/caldo-global/SendMail'
import EmailCompose from '@/views/caldo-global/EmailCompose'
import {mapGetters} from "vuex";
import axiosIns from "@/libs/axios";
import ToastMixin from "@/Mixins/ToastMixin";
import FormMixin from "@/Mixins/FormMixin";
import SidebarPaymentDatabase from "@/views/caldo-global/SidebarPaymentDatabase";
import SidebarProjectDatabase from "@/views/caldo-global/SidebarProjectDatabase";
import moment from "moment";
import _ from "lodash";
import caldoSettings from "@/caldoSettings";

export default {
   components: {
	  SidebarPaymentDatabase,
	  BCard,
	  BAlert,
	  BLink,
	  BRow,
	  BCol,
	  UserViewUserInfoHeader,
	  UserViewUserInfoBody,
	  UserViewProjects,
	  UserViewPayments,
	  SidebarPayment,
	  SidebarProjectDatabase,
	  SendMail,
	  EmailCompose,
   },
   directives: {
	  "b-toggle": VBToggle,
   },
   mixins: [ToastMixin, FormMixin],
   data() {
	  return {
		 shallShowEmailComposeModal: false,
		 allProject: [],
		 projectAllInContact: [],
		 allProjectAll: [],
		 allCategories: [],
		 count:0,
		 form: {
			"title": null,
			"contactId": this.$route.params.userId,
			"categoryId": null,
			"status": 'Σε εξέλιξη',
			"startDate": moment().format('YYYY-MM-DD'),
			"deadline": null,
		 },
		 pay: {
			"reference": null,
			"contactId": this.$route.params.userId,
			"projectId": null,
			"status": null,
			"price": 0,
			"date": null,
			"notes": null,
			"discount": null,
			edit: false,
		 },
		 num: 0
	  }
   },
   computed: {
	  ...mapGetters(["getContact"]),
	  crumbs: function () {
		 let paths = this.$route.path.split("/");
		 let breadcrumb = this.$route.meta.breadcrumb;
		 paths.forEach((path, i) => {
			if (breadcrumb[i]) {
			   breadcrumb[i].text = breadcrumb[i].text
				   ? breadcrumb[i].text
				   : path;
			}
		 });
		 return 'asdas';
	  },
   },
   methods: {
	  refresh(){
		 this.count ++;
	  },

	  async onSubmit() {
		 try {

			await this.$store.dispatch("storeProjectCategory", this.removeEmpty(this.form));
			await this.$store.dispatch('fetchContact', this.$route.params.userId)
			await this.$store.getters.getContact

			this.$root.$emit('bv::toggle::collapse', 'sidebar-project-database')
			this.resetField(this.form);

			this.form.contactId = this.$route.params.userId

			this.notify(`Δημιουργήθηκε`)


			this.projectAllInContact = this.$store.getters.getContact.projects

		 } catch (e) {

		 }

	  },
	  async onSubmitPay() {


		 const form = {

			"projectId": this.pay.projectId.id,
			"contactId": parseInt(this.$route.params.userId),
			"status": this.pay.status,
			"price": this.pay.price,
			"date": this.pay.date,
			"notes": this.pay.notes,
			"reference": moment().valueOf().toString().substring(5, 10)
		 }

		 try {
			await this.$store.dispatch("storePaymentCategory", this.removeEmpty(form));
			await this.$store.dispatch('fetchContact', this.$route.params.userId)
			await this.$store.getters.getContact


			this.projectAllInContact = _.find(this.$store.getters.getAllContacts, {id: parseInt(this.$route.params.userId)})?.projects
c
			this.$root.$emit('bv::toggle::collapse', 'sidebar-payment-database')
			this.resetField(this.pay);

			this.notify(`Δημιουργήθηκε`)

		 } catch (e) {

		 }

	  },
	  async fetchData() {
		 const responseProject = await axiosIns.get(`/contact`, {
			params: {
			   rpp: 100,
			   orderBy: ["surname"],
			}
		 })
		 this.allCategories = responseProject.data.data

		 const responseProjects = await axiosIns.get(`/project-category`, {
			params: {
			   rpp: 100
			}
		 })
		 this.allProject = responseProjects.data
		 const responseProjectsAll = await axiosIns.get(`/project`, {
			params: {
			   rpp: 100
			}
		 })
		 this.allProjectAll = responseProjectsAll.data.data
	  }
   },
   beforeRouteLeave(to, from, next) {
	  caldoSettings.removeBreadcrumb();
	  return next()
   },
   async created() {

	  this.allProject = []
	  this.allProjectAll = []
	  this.allCategories = []
	  this.form = {}

	  await this.$store.dispatch('fetchContact', this.$route.params.userId)
	  await this.$store.getters.getContact


	  if (this.$route.name === 'contacts-view') {
		 const surname = this.$store.getters.getContact.surname
		 const name = this.$store.getters.getContact.name

		 let li = document.createElement('li');
		 li.className = "custom";
		 const test = document.querySelector('.breadcrumb')

		 let contain = test.lastChild.classList.contains('custom')

		 if (!contain) {
			li.textContent = ` ${surname} ${name}`;
			test.appendChild(li);
		 }

	  }
	  this.form.contactId = this.$route.params.userId;


	  await this.$store.dispatch('fetchAllContacts')
	  await this.fetchData();
	  this.projectAllInContact = _.find(this.$store.getters.getAllContacts, {id: parseInt(this.$route.params.userId)})?.projects

   }


};


</script>

<style scoped>

</style>