s
<template>
   <b-card no-body>
	  <b-card-header>
		 <div>
			<b-card-title>{{ $t('contacts.transactions') }}</b-card-title>
			<b-card-text class="font-small-2">
			   {{ $t('contacts.ten-recent') }}
			</b-card-text>
		 </div>

		 <b-button
			 v-ripple.400="'rgba(113, 102, 240, 0.15)'"
			 :to="{ name:'reports-transactions', params: { userId: $route.params.userId }}"
			 href="#"
			 size="sm"
			 variant="outline-primary"
			 @click="removeName"
		 >
			{{ $t('contacts.kartela') }}
		 </b-button>
	  </b-card-header>

	  <b-table
		  v-if="items.length"
		  :fields="fields"
		  :items="items"
		  hover
		  responsive
		  small
		  sticky-header="stickyHeader"
	  >
		 <template #cell(name)="data">
			<div class="d-flex align-items-center">
			   <b-avatar
				   :variant="data.value.avatarColor"
				   class="mr-1"
				   size="32"
			   >
				  <feather-icon
					  :icon="data.value.avatarIcon"
					  size="18"
				  />
			   </b-avatar>
			   <div>
				  <div class="font-weight-bold">
					 {{ truncate(data.value.projectName) }}
				  </div>
				  <div class="font-small-2">
					 {{ data.value.paymentType }} - <span class="text-muted"> {{
						convertDate(data.value.paymentDate)
																			  }} </span>
				  </div>
			   </div>
			</div>
		 </template>

		 <template #cell(order_status)="data">
			<b-badge
				:variant="data.value.variant"
				pill
			>
			   {{ data.value.status }}
			</b-badge>
		 </template>

		 <template #cell(price)="data">
			{{ '€' + data.value }}
		 </template>


	  </b-table>


	  <div v-else
		   class="text-center py-10">
		 <feather-icon
			 class="text-primary op2"
			 icon="MehIcon"
			 size="70"
		 />
		 <h3 class="my-1 op2">{{ $t('contacts.notfound-transactions') }}</h3>
	  </div>

   </b-card>


</template>

<script>
import {
   BAvatar,
   BBadge,
   BButton,
   BCard,
   BCardHeader,
   BCardText,
   BCardTitle,
   BCol,
   BDropdown,
   BDropdownItem,
   BProgress,
   BRow,
   BTable,
   VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CurrencyMixin from "@/Mixins/CurrencyMixin";
import moment from "moment";
import _ from "lodash";
import caldoSettings from "@/caldoSettings";

export default {
   components: {
	  BRow,
	  BCol,
	  BCard,
	  BCardHeader,
	  BTable,
	  BCardTitle, BDropdown, BDropdownItem, BCardText, BProgress, BBadge, BButton, BAvatar,
   },
   mixins: [CurrencyMixin],
   props: {
	  contact: [Object, Array],
   },
   directives: {
	  Ripple,
	  "b-toggle": VBToggle,
   },
   data() {
	  return {
		 fields: [
			{key: 'name', label: 'ΤΥΠΟΣ'},
			{key: 'price', label: 'ΠΟΣΟ'},
		 ],
		 items: [],

	  }
   },
   methods: {

	  removeName() {
		 const test = document.querySelector('.breadcrumb')

		 let contain = test.lastChild.classList.contains('custom')
		 if (contain) {
			test.removeChild(test.lastChild);
		 }
	  },
	  convertDate(date) {
		 return moment(date).format('DD-MM-YYYY')
	  },

	  truncate(value) {
		 return caldoSettings.truncateString(value)
	  }
   },

   watch: {
	  contact(n, o) {
		 let payments = this.contact.payments.map(el => {
			return {
			   name: {
				  avatarColor: 'light-success',
				  avatarIcon: 'CheckIcon',
				  projectName: el.project.title,
				  paymentType: 'Είσπραξη',
				  paymentDate: el.date
			   },
			   price: this.convertFormatPrice(el.price),
			}
		 })


		 let paid = this.contact.projects.map(el => {
			return {
			   name: {
				  avatarColor: 'light-danger',
				  avatarIcon: 'MinusIcon',
				  projectName: el.title,
				  paymentType: 'Χρέωση',
				  paymentDate: el.startDate
			   },
			   price: this.convertFormatPrice(el.total),
			}
		 })

		 let newArr = [...payments, ...paid];


		 var array = _.map(
			 _.sortBy(newArr, o => new Date(o.name.paymentDate))
		 );


		 this.items = _.reverse(array.slice(0, 10))


	  }
   }


}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';


.card-company-table ::v-deep td .b-avatar.badge-light-company {
   .dark-layout & {
	  background: $theme-dark-body-bg !important;
   }
}


.table.table-sm th:first-child, .table.table-sm td:first-child {
   padding-left: 1.50rem !important;
}


.table.table-sm td {
   padding: 0.6rem 0.5rem;
}


.py-10 {
   padding: 50px 10px;
}


.op2 {
   opacity: 0.6;
}
</style>

