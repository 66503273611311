<template>
   <b-card no-body>
	  <b-card-header>
		 <div>
			<b-card-title>{{ $t('generic.projects') }}</b-card-title>
			<b-card-text class="font-small-2">
			   {{ $t('projects.total-projects') }}: {{ totalProject }}
			</b-card-text>
		 </div>

		 <b-dropdown
			 class="chart-dropdown"
			 no-caret
			 right
			 toggle-class="p-0"
			 variant="link"
		 >
			<template #button-content>
			   <feather-icon
				   class="text-body cursor-pointer"
				   icon="MoreVerticalIcon"
				   size="18"
			   />
			</template>
			<b-dropdown-item href="#"
							 selected
							 @click="selectedFilter">
			   {{ $t('generic.all') }}
			</b-dropdown-item>
			<b-dropdown-item href="#"
							 @click="selectedFilter">
			   {{ $t('generic.in-progress') }}
			</b-dropdown-item>
			<b-dropdown-item href="#"
							 @click="selectedFilter">
			   {{ $t('generic.paused') }}
			</b-dropdown-item>
			<b-dropdown-item href="#"
							 @click="selectedFilter">
			   {{ $t('generic.completed') }}
			</b-dropdown-item>
			<b-dropdown-item href="#"
							 @click="selectedFilter">
			   {{ $t('generic.canceled') }}
			</b-dropdown-item>
		 </b-dropdown>
	  </b-card-header>

	  <b-table
		  v-if="items.length"
		  :fields="fields"
		  :items="items"
		  responsive
		  small
		  sticky-header="stickyHeader"
	  >
		 <template #cell(name)="data">
			<template v-if="data.item.show">
			   <div>
				  <div>
					 <b-link :to="{ name: 'projects-view', params: { projectId: data.item.id } }"
							 class="mr-1 font-weight-bold d-block text-nowrap"
							 @click="removeName">
						{{ truncate(data.value.first) }}
					 </b-link>
					 <div class="font-small-2 text-muted">
						{{ data.value.last }}
					 </div>
				  </div>
			   </div>
			</template>
		 </template>

		 <template #cell(order_status)="data">

			<!-- Αυτό είναι το σωστό template αλλά δεν ήξερα πως να τα φέρω από το store -->
			<!-- <template #option="{ status, color }">
				<div class="rounded-circle d-inline-block mr-50" :class="`bg-${color}`" style="height:10px;width:10px"/>
				<span> {{ status }}</span>
			</template> -->

			<b-badge
				v-if="data.item.show"
				:variant="data.value.variant"
				pill
			>
			   {{ data.value.status || 'Δεν έχει κατάσταση ' }}
			</b-badge>
		 </template>

		 <!-- Column: Balance -->
		 <template #cell(balance)="data">
			<template v-if="data.item.show">
			   <template v-if="data.value === 0">
				  <b-badge
					  pill
					  variant="light-success"
				  >
					 {{ $t('generic.payed') }}
				  </b-badge>
			   </template>
			   <template v-else>
				  {{ '€' + data.value }}
			   </template>
			</template>
		 </template>

	  </b-table>

	  <div v-else
		   class="text-center py-10">
		 <feather-icon
			 class="text-primary op2"
			 icon="MehIcon"
			 size="70"
		 />
		 <h3 class="my-1 op2">{{ $t('contacts.notfound-projects') }}</h3>
	  </div>

   </b-card>


</template>

<script>
import {
   BBadge,
   BCard,
   BCardHeader,
   BCardText,
   BCardTitle,
   BCol,
   BDropdown,
   BDropdownItem,
   BLink,
   BProgress,
   BRow,
   BTable
} from 'bootstrap-vue'
import CurrencyMixin from "@/Mixins/CurrencyMixin";
import _ from "lodash";
import axiosIns from "@/libs/axios";

import caldoSettings from "@/caldoSettings";

export default {
   components: {
	  BRow,
	  BCol,
	  BCard,
	  BLink,
	  BCardHeader,
	  BTable,
	  BCardTitle, BDropdown, BDropdownItem, BCardText, BProgress, BBadge,
   },
   mixins: [CurrencyMixin],
   props: {
	  contact: [Object, Array],
	  num: Number
   },
   data() {
	  return {
		 fields: [
			{key: 'name', label: 'ΕΡΓΟ'},
			{key: 'order_status', label: 'ΚΑΤΑΣΤΑΣΗ'},
			{key: 'balance', label: 'ΥΠΟΛΟΙΠΟ'},
		 ],
		 items: [],
		 categories: []
	  }
   },
   methods: {
	  truncate(name) {
		 return caldoSettings.truncateString(`${name} `, 30)
	  },
	  removeName() {
		 const test = document.querySelector('.breadcrumb')

		 let contain = test.lastChild.classList.contains('custom')


		 if (contain) {
			test.removeChild(test.lastChild);
		 }
	  },
	  selectedFilter(e) {
		 const selected = e.target.textContent.replaceAll(' ', '')
		 let projects = this.contact.projects.map(el => {
			if (el.status?.replaceAll(' ', '') == selected || selected == 'Όλα' || (el.status == null && selected == 'Δενέχεικατάσταση')) {
			   const categoryName = _.find(this.categories, {id: el.categoryId})?.title
			   return {
				  id: el.id,
				  show: true,
				  name: {first: el.title, last: categoryName},
				  order_status: {status: el.status, variant: this.getStatus(el.status)},
				  balance: this.convertFormatPrice(el.remainder),
			   }
			}
		 })
		 this.items = projects.filter(item => item);
	  },
	  getStatus(status) {

		 const statusOptions = [
			{text: 'Ολοκληρωμένο', value: 'light-success'},
			{text: 'Σε εξέλιξη', value: 'light-primary'},
			{text: 'Σε αναμονή', value: 'light-warning'},
			{text: 'Ακυρωμένο', value: 'light-danger'},
		 ]


		 return _.find(statusOptions, {text: status}).value


	  }
   },
   computed: {
	  totalProject() {
		 return this.contact.projects?.length
	  }
   },
   watch: {
	  async contact(n, o) {

		 let projects = this.contact.projects.map(el => {
			const categoryName = _.find(this.categories, {id: el.categoryId})?.title
			return {
			   id: el.id,
			   show: true,
			   name: {first: el.title, last: categoryName},
			   // order_status: {status: el.status, variant: 'light-success'},
			   order_status: {status: el.status, variant: this.getStatus(el.status)},
			   balance: this.convertFormatPrice(el.remainder),
			}
		 })

		 this.items = projects
		 this.cloneItems = projects

	  }
   },
   async created() {

	  const response = await axiosIns.get(`/project-category`)

	  this.categories = response.data;

   }


}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';


.card-company-table ::v-deep td .b-avatar.badge-light-company {
   .dark-layout & {
	  background: $theme-dark-body-bg !important;
   }
}


.table.table-sm th:first-child, .table.table-sm td:first-child {
   padding-left: 1.50rem;
}


.table.table-sm td {
   padding: 0.6rem 0.5rem;
}


.py-10 {
   padding: 50px 10px;
}


.op2 {
   opacity: 0.6;
}

</style>

